
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { initReactI18next } from 'react-i18next';
import FloatingSpaceShip from './components/FloatingSpaceShip';
import Home from './Home';
import './index.css';
import reportWebVitals from './reportWebVitals';


i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'es'],
    fallbackLng: 'en',
    debug: false,
    // Options for language detector
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    // react: { useSuspense: false },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
  })

const loadingMarkup = (
  <img alt='loading' src="img/loading.gif" style={{
    height: "100vh",
    width: "100vw"
  }}
  />
)
ReactDOM.render(
  <Suspense fallback={loadingMarkup} >
    <React.Fragment>
      <Home />
      <FloatingSpaceShip />
    </React.Fragment>
  </Suspense>,
  document.getElementById('root')
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
