import { useTranslation } from "react-i18next"
import contracts from "../constants/contracts"
import alienSaleAbi from "../abi/alienSale.json"
import Modal from "react-modal"
import { useState } from "react"
import * as customAlert from "js-alert"



const FloatingSpaceShip = () => {


    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            background: 'url(img/popup_aliens.png)',
            backgroundSize: 'cover',
            backgroundPosition: 'left',
            backgroundRepeat: 'round',
            border: 'none',
            fontFamily: 'sans-serif',
            color: 'white'
        }

    }

    const [modalIsOpen, setIsOpen] = useState(false)
    const [amountReserved, setReserved] = useState(0)
    const [amountClaimed, setClaimed] = useState(0)

    async function afterOpenModal() {
        const amounts = await window.alienSaleContract.methods.getUserInfo(window.address[0]).call()
        setReserved(amounts[0]['nftsBought'])
        setClaimed(amounts[0]['nftSended'])
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00'
    }

    function closeModal() {
        setIsOpen(false)
    }

    async function openModal() {
        if(modalIsOpen){
            closeModal()
            return
        }
        try {
            await window.connectWeb3()

            if (window.$web3) {
                window.alienSaleContract = new window.$web3.eth.Contract(
                    alienSaleAbi,
                    contracts.saleAddress
                )

                window.address = await window.$web3.eth.getAccounts()

                setIsOpen(true)
            }
        } catch (e) {
            customAlert.alert(t("Verify your connection to web3"), t("Error"), window.errorImage)
        }
    }

    async function claimAlien() {
        customAlert.alert(t("You can't claim right now!"), t("Error"), window.errorImage)
    }
    const { t } = useTranslation()
    return (
        <>
            <div className="left-ticket" onClick={openModal}>

                <p>{t('APY ALIENS INFO')}</p>
                <img src="/img/roadmap-icon.png" alt="Space Apy Aliens" />
            </div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Buy Modal"
                ariaHideApp={false}
                closeTimeoutMS={2000}
            >
                <div className="modal-aliens">
                    <div className="justify-between flex flex-wrap pt-10">
                        <div className="w-full md:w-[50%]">
                            <h1 className='text-white text-center flex justify-center text-md mb-0 md:mb-0 mt-4 md:text-2xl font-bold'>{t('Here you can see the Aliens you have reserved !')}</h1>
                        </div>
                        <div className="space-modal cursor-pointer" onClick={claimAlien}>
                            <img src="img/nave.png" alt="Space Apy Aliens"></img>
                            <p>{t('CLAIM ALIENS')}</p>
                        </div>

                    </div>
                    <div className="amount-modal flex flex-wrap pt-10">
                        <p>{t('Reserved')}: {amountReserved}</p>
                        <p>{t('Claimed')}: {amountClaimed}</p>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default FloatingSpaceShip