import { default as React, lazy, useLayoutEffect } from 'react';
import {
  BrowserRouter, Navigate, Route, Routes, useLocation
} from "react-router-dom";




function Home() {

  // this does for Scroll to top when router change
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
  }

  const App = lazy(() => import('./App'));
  const TermCondition = lazy(() => import('./views/TermCondition'));

  return <div>
    <BrowserRouter>
      <Wrapper>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="condition" element={<TermCondition />} />
          {/* <Route path="/en" element={<App />} />
      <Route path="/es" element={<App />} /> */}
          <Route
            path="*"
            element={<Navigate to="/" />}
          />
        </Routes>
      </Wrapper>
    </BrowserRouter>
  </div>;
}

export default Home;
